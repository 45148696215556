<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientqqqq" x1="0.751" x2="0.312" y2="1">
     <stop offset="0" stop-color="#61719d"/>
     <stop offset="1" stop-color="#080808"/>
    </linearGradient>
   </defs>
   <g id="GitHub" transform="translate(-370 -288)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(370 288)" width="60"/>
    <path d="M11.664,27.939c0,.141-.162.253-.366.253-.232.021-.394-.091-.394-.253,0-.141.162-.253.366-.253C11.481,27.665,11.664,27.778,11.664,27.939Zm-2.186-.316c-.049.141.091.3.3.344a.33.33,0,0,0,.436-.141c.042-.141-.091-.3-.3-.366A.361.361,0,0,0,9.477,27.623Zm3.107-.12c-.2.049-.344.183-.323.344.021.141.2.232.415.183s.344-.183.323-.323S12.788,27.482,12.585,27.5ZM17.211.563A16.858,16.858,0,0,0,0,17.717,17.626,17.626,0,0,0,11.917,34.534c.9.162,1.216-.394,1.216-.851,0-.436-.021-2.84-.021-4.317,0,0-4.921,1.055-5.955-2.1,0,0-.8-2.046-1.954-2.573,0,0-1.61-1.1.112-1.083a3.711,3.711,0,0,1,2.714,1.814A3.716,3.716,0,0,0,13.154,26.9a3.907,3.907,0,0,1,1.125-2.369c-3.93-.436-7.9-1.005-7.9-7.769A5.328,5.328,0,0,1,8.043,12.62a6.643,6.643,0,0,1,.183-4.774c1.469-.457,4.851,1.9,4.851,1.9a16.609,16.609,0,0,1,8.83,0s3.382-2.362,4.851-1.9a6.639,6.639,0,0,1,.183,4.774,5.465,5.465,0,0,1,1.814,4.141c0,6.784-4.141,7.326-8.071,7.769a4.158,4.158,0,0,1,1.2,3.262c0,2.369-.021,5.3-.021,5.877,0,.457.323,1.012,1.216.851a17.464,17.464,0,0,0,11.8-16.8C34.871,7.966,26.962.563,17.211.563ZM6.834,24.811c-.091.07-.07.232.049.366.112.112.274.162.366.07.091-.07.07-.232-.049-.366C7.087,24.768,6.925,24.719,6.834,24.811Zm-.759-.569c-.049.091.021.2.162.274a.209.209,0,0,0,.3-.049c.049-.091-.021-.2-.162-.274C6.236,24.15,6.124,24.171,6.074,24.241Zm2.278,2.5c-.112.091-.07.3.091.436.162.162.366.183.457.07.091-.091.049-.3-.091-.436C8.655,26.653,8.444,26.632,8.352,26.744Zm-.8-1.033c-.112.07-.112.253,0,.415s.3.232.394.162a.321.321,0,0,0,0-.436C7.846,25.689,7.663,25.619,7.551,25.711Z" data-name="Icon awesome-github" id="Icon_awesome-github" style="fill: url(#linear-gradientqqqq);" transform="translate(383 300.438)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>